import React from "react";
import { graphql, Link } from "gatsby";
import Container from "~components/common/Container";
import Layout from "~components/layouts/Layout";
import H1 from "~components/typography/H1";
import P from "~components/typography/P";
import CardProjekt from "~components/common/CardProjekt";
import NumberedSection from "~components/typography/NumberedSection";
import Divider from "~components/common/Divider";
import Breadcrumb from "~components/layouts/Breadcrumb";

export default function Page({ data, location }) {
	return (
		<Layout
			location={location}
			title="Projekte & Referenzen von Lautenschlager"
			desc="Jedes Projekt ist so individuell, wie das Business-Modell dahinter. Siehe Dir eine Auswahl unserer Web-Lösungen an."
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<Breadcrumb pages={[{ name: "Projekte", to: "/projekte/" }]} />
			<Container noPadding className="py-6">
				<Divider className="mb-4" />
				<NumberedSection text="Individuell" number="01" className="mb-5" />
				<H1 display className="mb-5 font-display">
					Projekte & Referenzen
				</H1>
				<P className="mb-5 max-w-xl">
					Jedes Projekt ist so individuell, wie das Business-Modell dahinter. Das möchten wir durch individuelle Designs
					und Lösungen widerspiegeln. Entdecke unsere aktuellen Website-Projekte und überzeuge Dich selbst. Viele
					unserer Referenzen findest Du auch als Use Cases im{" "}
					<Link to="/blog/" className="underline">
						Blog
					</Link>
					.
				</P>
			</Container>

			<div className="bg-pattern grid gap-3 p-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
				{data.allProjekte.nodes.map((projekt) => (
					<CardProjekt
						title={projekt.title}
						image={projekt.remoteImage}
						href={projekt.link}
						tech={projekt.tech}
						key={projekt.title}
						asHTwo
					/>
				))}
			</div>
		</Layout>
	);
}

export const query = graphql`
	query {
		allProjekte {
			nodes {
				title
				remoteImage {
					childImageSharp {
						gatsbyImageData(width: 711)
					}
				}
				link
				tech {
					value
				}
			}
		}

		seoImage: file(relativePath: { eq: "pages/leistungen/leistungen_seo-image.jpg" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		site {
			siteMetadata {
				siteUrl
				contact {
					street
					plz
					phone
					mail
					city
				}
			}
		}
	}
`;
