import { ExternalLinkIcon } from "@heroicons/react/solid";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import H2 from "~components/typography/H2";
import H3 from "~components/typography/H3";
import Button from "./Button";

interface Props {
	title: string;
	image: IGatsbyImageData;
	href: string;
	tech: {
		value: string,
	}[];
	asHTwo?: boolean;
}

const CardProjekt: React.FC<Props> = ({ title, image, href, tech, asHTwo = false }) => {
	const imageRes = getImage(image);

	return (
		<div className="relative group">
			{imageRes ? <GatsbyImage image={imageRes} alt={`${title} Projekt`} /> : null}

			<div
				className="z-10 flex-col justify-between w-full p-3 transition-opacity duration-300 ease-out bg-white opacity-100 bottom-4 left-4 group-hover:opacity-100 md:absolute md:flex md:w-auto md:p-6 md:opacity-0"
				style={{ minWidth: 280 }}
			>
				{asHTwo ? (
					<H2 className="mb-3 font-display md:block">{title}</H2>
				) : (
					<H3 className="mb-3 font-display md:block">{title}</H3>
				)}
				<div className="flex flex-wrap gap-1 mb-5">
					{tech.map((entry) => (
						<span className="p-2 text-xs bg-gray-100" key={entry.value}>
							{entry.value}
						</span>
					))}
				</div>
				<Button href={href} rel="noopener dofollow" target="_blank" small>
					Zur Website<span className="sr-only"> von {title} </span><ExternalLinkIcon className="inline w-4 h-4 ml-3" />
				</Button>
			</div>
		</div>
	);
};

export default CardProjekt;
